<template>
  <div class="overflow-hidden">
    <HeroV2 :visible.sync="visible">
      <XrayWrapperMinnetonka />
    </HeroV2>
    <main>
      <div class="mt-24 mt-lg-32">
        <b-container fluid="sm" class="position-relative">
          <b-row class="justify-content-center">
            <b-col lg="8" md="10">
              <h2
                class="text-5xl text-center text-md-6xl text-xl-7xl font-realist font-weight-black text-white tracking-tight leading-none text-blue-500 mb-12"
              >
                What is True360?
              </h2>
              <ul class="list text-md text-sm-lg text-md-2xl mb-0">
                <li>
                  True360 is the nation's leading third-party vehicle inspection
                  company.
                </li>
                <li>
                  Our technicians inspect vehicles for structural integrity and
                  notes any previous repair work.
                </li>
                <li>A True360 Certified Inspection is $199.</li>
                <li>
                  Our True360 reports will populate Third-Party Vehicle History
                  Reports, including Carfax and Autocheck to give a complete
                  history of the vehicle.
                </li>
                <li>
                  We Give you Current Vehicle Condition as-well-as previous
                  vehicle history.
                </li>
                <li>
                  Each passing vehicle is awarded the Certificate of Integrity.
                </li>
              </ul>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div class="mt-24 mt-lg-32">
        <b-container fluid="sm">
          <Assess />
        </b-container>
      </div>
      <b-container class="mt-24 pb-24 pb-lg-32">
        <b-row>
          <b-col
            cols="12"
            lg="8"
            offset-lg="2"
            xl="10"
            offset-xl="1"
            class="text-center"
          >
            <h2
              class="text-5xl text-md-6xl text-xl-7xl font-realist font-weight-black text-white tracking-tight leading-none text-blue-500"
            >
              Trust in Transparency
            </h2>
            <div>
              <img
                src="@/assets/img/T360_98Score_Report_BMW.png"
                class="img-fluid mb-8"
                alt="True 360 Report"
              />
              <b-button
                variant="primary"
                size="lg"
                class="btn-green-500 border-0 rounded-lg py-4 font-weight-bold d-block d-md-inline-block font-weight-bold font-realist-normal text-xl text-md-2xl px-12 text-uppercase tracking-wider"
                href="https://buy.stripe.com/9AQdTe2mt7teb84144"
              >
                Request True360 Inspection
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </main>
  </div>
</template>

<script>
import XrayWrapperMinnetonka from "@/components/XrayWrapperMinnetonka";
import HeroV2 from "@/components/global/HeroV2";

import Assess from "@/components/MinnetonkaAssess";

export default {
  name: "Minnetonka",
  components: {
    HeroV2,

    Assess,

    XrayWrapperMinnetonka
  },
  watch: {
    visible() {
      this.$emit("update:heroIsVisible", this.visible);
    }
  },
  data() {
    return {
      hoverForDealers: false,
      hoverForCommercial: false,
      visible: true,
      testimonials: [
        {
          name: "Tony Guerrera",
          title: "Pre-Owned Sales Manager",
          companyName: "Vista BMW",
          quote:
            "True360 reports add a layer of transparency to each sale. Each report is like buying peace of mind for us and the customer.",
          companyLogo: "@/assets/img/testimonials/logos/vista.png"
        },
        {
          name: "Fred Mueller",
          title: "Pre-Owned Manager",
          companyName: "Dean Honda",
          quote:
            "With the True360 we were able to defend the car and provide a 3rd party, unbiased report card on the vehicle. We were able to maintain value, especially during the negotiation process.",
          companyLogo: "@/assets/img/testimonials/logos/vista.png"
        },
        {
          name: "Marty Francis",
          title: "Pre-Owned Directory",
          companyName: "Lilliston Auto Group",
          quote:
            "I would encourage every dealership to use True360. This service is an incredible sales tool that will close deals and help you retain profit.",
          companyLogo: "@/assets/img/testimonials/logos/vista.png"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.list {
  li {
    margin-bottom: 1rem;
  }
}
</style>
