<template>
  <div>
    <div class="xray-container">
      <div class="xray hero-cutout cutout-down" ref="xrayWrapper" data-wrapper>
        <div class="text-container">
          <h4
            class="text-xl text-md-3xl text-lg-4xl text-white font-weight-bold font-realist tracking-tight leading-none mb-md-8 mb-4"
          >
            What is a TRUE360 Inspection Report,
            <br class="d-inline d-lg-none d-xl-none" />and Why Do You Need It?
          </h4>
          <h1
            class="text-4xl text-md-6xl text-lg-8xl text-white font-weight-black font-realist tracking-tight leading-none mb-md-8 mb-4"
          >
            BMW of Minnetonka
          </h1>
          <span
            class="font-weight-medium text-md-xl text-md leading-normal text-white"
          >
            Giving you peace of mind &bull; Buying with confidence
          </span>
        </div>

        <svg
          preserveAspectRatio="xMidYMax meet"
          style="--x: 774px; --y: 1022px;"
          ref="xray"
          height="100%"
          width="100%"
          viewBox="0 0 2000 1382"
        >
          <defs>
            <filter id="shadow">
              <feDropShadow
                dx="0.25"
                dy="2"
                stdDeviation="0.8"
                flood-opacity="0.5"
              />
            </filter>
            <clipPath :id="`clip-${_uid}`">
              <circle cx="774" cy="1022" stroke="#fff" r="144" />
            </clipPath>
          </defs>

          <image
            width="100%"
            height="100%"
            :xlink:href="require('@/assets/img/hero-v3-home-body.png')"
          />

          <circle
            :id="`border-${_uid}`"
            cx="774"
            cy="1022"
            r="150"
            :fill="bgColor"
            stroke="#fff"
            stroke-width="10"
            style="filter:url(#shadow);"
          />
          <g :clip-path="`url(#clip-${_uid})`">
            <image
              width="100%"
              height="100%"
              :xlink:href="require('@/assets/img/hero-v3-home-xray.png')"
            />
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "xrayWrapper",
  props: { bgColor: { type: String, default: "#001f32" } },
  mounted() {
    this.initXray();
  },
  methods: {
    initXray() {
      const el = this.$refs.xray;

      let point = el.createSVGPoint();

      function getCoordinates(e, svg) {
        point.x = e.clientX;
        point.y = e.clientY;
        return point.matrixTransform(svg.getScreenCTM().inverse());
      }

      function Item(config) {
        Object.keys(config).forEach(function(item) {
          this[item] = config[item];
        }, this);
        this.el.addEventListener("mousemove", this.mouseMoveHandler.bind(this));
        this.el.addEventListener("touchmove", this.touchMoveHandler.bind(this));
      }

      Item.prototype = {
        update: function update(c) {
          this.clip.setAttribute("cx", c.x);
          this.clip.setAttribute("cy", c.y);
          this.circle.setAttribute("cx", c.x);
          this.circle.setAttribute("cy", c.y);
          this.svg.style.setProperty("--x", c.x + "px");
          this.svg.style.setProperty("--y", c.y + "px");
        },
        mouseMoveHandler: function mouseMoveHandler(e) {
          this.update(getCoordinates(e, this.svg));
          this.el.classList.add("interacted");
        },
        touchMoveHandler: function touchMoveHandler(e) {
          e.preventDefault();
          var touch = e.targetTouches[0];
          if (touch) return this.update(getCoordinates(touch, this.svg));
        }
      };

      new Item({
        el: el.parentElement,
        svg: el,
        clip: document.querySelector("#clip-" + this._uid + " circle"),
        circle: document.querySelector("circle#border-" + this._uid)
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.xray-container {
  width: 100%;
  overflow: hidden;
  background: linear-gradient(
    180deg,
    rgba(14, 103, 156, 1) 0%,
    rgba(92, 173, 218, 1) 50%,
    rgba(204, 220, 229, 1) 100%
  );

  height: 300px;
  @media (min-width: 415px) {
    height: 450px;
  }
  @media (min-width: 767px) {
    height: 500px;
  }
  @media (min-width: 992px) {
    height: 650px;
  }
  @media (min-width: 1367px) {
    height: 700px;
  }
  @media (min-width: 1719px) {
    height: calc(1382 / 2000 * 1000vw);
    max-height: 800px;
  }
}
.xray {
  position: relative;
  height: 100%;
  width: 100%;
  background-image: url("../assets/img/hero-v5-backdrop.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
}

svg {
  position: relative;
  z-index: 0;
  max-height: initial;
  max-width: initial;
  height: 100%;
  width: 100%;
}

.hero-cutout {
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    bottom: -2px;
    height: 25%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;
  }
}

.cutout-up {
  &::before {
    background-image: url("~@/assets/img/cutout-up.svg");
  }
}

.cutout-down {
  &::before {
    background-image: url("~@/assets/img/cutout-down.svg");
  }
}

.text-container {
  margin: 6vw auto 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 1;
  padding: 0 5vw;
  text-align: center;

  @media (max-width: 767px) {
    padding: 0 5vw;
  }

  @media (min-width: 1200px) {
    margin-top: 80px;
  }
  @media (min-width: 1719px) {
    margin-top: 100px;
  }

  h1 {
    text-shadow: 0.25vw 0.25vw 1vw rgba(#004e7d, 0.13);
  }

  span {
    display: block;
    font-size: 1.75vw;
    line-height: 1.3;
    margin: auto;
    font-weight: 400;
    text-shadow: 0.25vw 0.25vw 1vw rgba(#004e7d, 0.13);
    @media (max-width: 767px) {
      font-size: 13px;
      max-width: 100%;
    }
    max-width: 70%;
  }
}

circle {
  transform-origin: var(--x) var(--y);
  transform: scale(1);
  transition: transform 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.25));
}

image {
  transform-origin: bottom center;
  transition: transform 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-wrapper] {
  cursor: all-scroll;

  &.interacted {
    circle {
      transform: scale(0);
    }
  }
  &:hover {
    circle,
    image {
      transform: scale(1);
    }
  }
}
</style>
